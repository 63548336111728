import { Routes,Route } from 'react-router-dom';
import { Home } from '../Pages/Home';
import { Products } from '../Pages/Products';
import { ProductoInfo } from '../Pages/Producto-Info';
import { ContactUs } from '../Pages/ContactUs';

function Router(){
    return(
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/productos" element={<Products/>}/>
            <Route path='/productos/:id' element={<ProductoInfo/>}/>
            <Route path="/contacto" element={<ContactUs/>}/>
        </Routes>
    )
}

export {Router};