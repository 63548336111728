import "./Footer.css";
import facebook from '../Images/facebook-icon.svg'
import instagram from '../Images/instagram-icon.svg'
import whatsapp from '../Images/whatsapp-icon.svg'


function Footer(){
    return(
        <div className="footer row">
            <div className="footer-about-us col-sm-6 col-xs-12">
                <h2 className="footer-headline">Acerca de nosotros</h2>
                <p className="footer-description">Somos una tienda virtual dedicada a ofrecer los mejores juegos de mesa para el público costarricence</p>
            </div>
            <div className="footer-social-media col-sm-6 col-xs-12">
                <h2 className="footer-headline">Síguenos en nuestra redes sociales</h2>
                <a href="https://www.facebook.com/boomstore.cr/" target="_blank" rel="noreferrer"><img src={facebook} className="footer-social" alt="facebook" /></a>
                <a href="https://www.instagram.com/boomstorecr/?hl=en" target="_blank" rel="noreferrer"><img src={instagram} className="footer-social" alt="instagram" /></a>
                <a href="https://wa.me/+50688338724?text=Hola!%20Estoy%20interesado%20en%20sus%20productos%20vi%20su%20contacto%20en%20boomstorecr.com." target="_blank" rel="noreferrer"><img src={whatsapp} className="footer-social" alt="whatsapp" /></a>
            </div> 
            <div className="footer-end col-sm-12">
                <p>Boom Store Costa Rica</p>
            </div>
        </div>
    )
}


export{Footer};