import "./ProductIndex.css";
import data from "../DB/Products.json";
import { ProductCardForIndex } from "../ProductCardForIndex/ProductCardForIndex";

function ProductIndex(){
    

    return(
        <>
            <div className="ProductIndex">
                <div className="row">
                    {
                        data.map(product => {
                            return(
                            <ProductCardForIndex key = {product.productId}
                                                productId = {product.productId}
                                                productImageCard = {product.productImageCard}
                                                productName = {product.productName} />
                            )
                        })
                    }
                    
                </div>

            </div>
        </>
    )
}

export {ProductIndex}