import React from 'react';
import { Banner } from '../Banner/index';
import {SimpleCards} from '../Simple-Cards/index';
import {ShowProduct} from '../ShowProduct/index';
import { DetailCards } from '../DetailCards/index';
import { Accordion } from '../Accordion/index';
import { ImageSlider } from '../ImageSlider/ImageSlider.jsx';
import { Helmet } from 'react-helmet';




function Home(){
    return(
        <>
            <Helmet>
                  <title>Boom Store CR - Tu Tienda Online de Juegos de Mesa en Costa Rica </title>
                  <meta name="description" content="Descubre en Boom Store CR la mejor selección de juegos de mesa para todas las edades. Compra títulos clásicos y modernos, con envíos rápidos en Costa Rica. ¡Diversión asegurada para familia y amigos!" />
            </Helmet>
            <ImageSlider />
            <SimpleCards/>
            <ShowProduct/>
    
            <DetailCards/>
            <Accordion/>
        </>
    )
}

export{Home};

