import React from "react";
import { useParams } from "react-router";
import { ProductInformation } from "../ProductInformation/ProductInformation";

function ProductoInfo(){
    const {id} = useParams();
    return(
        <ProductInformation key = {id}
                            id = {id}/>
    )
}


export {ProductoInfo};