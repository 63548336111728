import "./ProductInformation.css";
import data from "../DB/Products.json";
import React from "react";
import listIcon from "../Images/List-icon.svg";
import ProductVideo from "../ProductVideo/ProductVideo";
import { Helmet } from "react-helmet";
//

function findProduct(id) {
  return data.filter(function (data) {
    return data.productId === id;
  });
}

function CreateWhatsappURL(product) {
  var url = "https://wa.me/50685026761?text=";
  var text = "Hola me gustaria comprar el juego " + product;
  var message = url + encodeURIComponent(text.trim());

  return message;
}

function isOutOfStock(stock) {
  if (stock === true) {
    return (
      <img
        src={require("../Images/agotado-full.png")}
        className="soldout"
        alt="Producto Agotado"
      />
    );
  }
}

function showEnglishNeeded(languaje, text){
  if(languaje === 'Ingles'){
    <p className="productText">
      <span className="productDetail">
      <img src={listIcon} className="Info-icon" alt="Dice icon" />
      {text} es necesario el ingles para jugarlo.
      </span>
    </p>
  }
}

function showOrderNeeded(orderNeeded){
  if(!orderNeeded){
    return(<p className="productText">
      <span className="productOrder">
      Este producto puedes adquirirlo solo por encargo.
      </span>
    </p>);
  }
}

function showVideo(videoExist){
  
  if (videoExist){
    return(
      <div className="col-12 col-sm-12">
        <ProductVideo videoURL={videoExist}/>
      </div>
    )
  }           
    
}

function ProductInformation(props) {
  const product = findProduct(props.id);

  return (
    

    <div className="ProductInformation">
      
      <div className="row">
        {product.map((info) => {
          var url = CreateWhatsappURL(info.productName);
          var weHaveStock = isOutOfStock(info.soldOut);
          return (
            <React.Fragment key={info.productId}>
              <Helmet>
                  <title>{info.productName} | Boom Store CR</title>
                  <meta name="description" content={info.metaTitle} />
              </Helmet>
              <div className="row">
                <a href="/productos" className="productInfoBackLink col-sm-12">
                  Productos / {info.productName}{" "}
                </a>
              </div>
              <div className="col-sm-6">
                <img
                  src={require("../Images/" + info.productImage)}
                  className="productImage"
                  alt={info.productName}
                />
              </div>
              <div className="col-sm-6 productInfo">
                <h2 className="productHeadline">{info.productName}</h2>
                <p className="productText">
                  <span className="productDetail">
                    <img src={listIcon} className="Info-icon" alt="Dice icon" />
                    Descripcion:
                  </span>{" "}
                  {info.productDescription}
                </p>
                <p className="productText">
                  <span className="productDetail">
                    <img src={listIcon} className="Info-icon" alt="Dice icon" />
                    Jugadores:
                  </span>{" "}
                  {info.players}
                </p>
                <p className="productText">
                  <span className="productDetail">
                    <img src={listIcon} className="Info-icon" alt="Dice icon" />
                    Tiempo de Juego:
                  </span>{" "}
                  {info.playTime}
                </p>
                <p className="productText">
                  <span className="productDetail">
                    <img src={listIcon} className="Info-icon" alt="Dice icon" />
                    Edad:
                  </span>{" "}
                  {info.ages}
                </p>
                <p className="productText">
                  <span className="productDetail">
                    <img src={listIcon} className="Info-icon" alt="Dice icon" />
                    Lenguaje:
                  </span>{" "}
                  {info.language}
                </p>
                <h3 className="productPrice">
                  <span className="productDetail">
                    <img src={listIcon} className="Info-icon" alt="Dice icon" />
                    Precio:{" "}
                  </span>
                  ₡{info.price}
                </h3>
                {showOrderNeeded(info.stock)}
                {weHaveStock}
                <div className="productInfoButtons row">
                  <a href={url} className="buyButton col-sm-6">
                    Comprar
                  </a>
                  <a href="/productos" className="backButton col-sm-6">
                    Ver productos
                  </a>
                </div>
              </div>
              {showVideo(info.youTubeLink)}
              
              
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export { ProductInformation };
