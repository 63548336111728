import './Accordion.css';

function Accordion(){
    return(

        <div className='Accordion-com'>
            <div className='row'>
                <h2 className='Accordion-headline'>Preguntas Frecuentes</h2>
            </div>
            <div className='row'>
                <div className='col-sm-2'></div>
                <div className='col-sm-8'>
                    <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <p className='accordion-question'>¿Dónde puedo adquirir sus productos?</p>
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p>Somos una tienda 100% virtual que realiza envíos a todo Costa Rica</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="faq-2">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <p className='accordion-question'>¿Cuáles son las formas de pago?</p>
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="faq-2" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <ul>
                                            <li>Efectivo</li>
                                            <li>Mediante Sinpe móvil</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="faq-3">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        <p className='accordion-question'>¿Hacen entregas a todo Costa Rica?</p>
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="faq-3" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p>Si, realizamos envíos a todo Costa Rica</p>
                                    </div>
                                </div>
                            </div> 
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="faq-4">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        <p className='accordion-question'>¿Cuántos días tardan los pedidos en llegar?</p>
                                    </button>
                                </h2>
                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="faq-4" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p>Los pedidos tardan entre 2-3 días hábiles.</p>
                                    </div>
                                </div>
                            </div> 
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="faq-5">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        <p className='accordion-question'>¿Como funcionan los encargos?</p>
                                    </button>
                                </h2>
                                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="faq-5" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p>Para realizar el encargo se realiza con un 50% por adelantado, una vez el producto está en nuestras manos, procedemos con informar al cliente, luego se cancela el otro 50% más envío si es necesario.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="faq-6">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                        <p className='accordion-question'>¿Cuando pueden durar los encargos en llegar?</p>
                                    </button>
                                </h2>
                                <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="faq-6" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p>Los encargos pueden durar de 7 a 15 días hábiles</p>
                                    </div>
                                </div>
                            </div>  
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="faq-7">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                        <p className='accordion-question'>Se pueden retirar los productos en algún lugar?</p>
                                    </button>
                                </h2>
                                <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="faq-7" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p>Si se puede retirar el producto en Santa Rosa, Santo Domingo, Heredia, pueden buscarnos en waze o google map como "Boom Store CR"</p>
                                    </div>
                                </div>
                            </div>                             
                    </div>
                </div> 
                <div className='col-sm-2'></div>
            </div>
        </div>
    )
}

export{Accordion};