import './DetailCards.css';
import product from '../Images/Burro_burro_new_product.webp'


function DetailCards(){
    return(
        <div className='DetailCards row'>
            <div className='detailCard col-sm-12 col-md-4'>
                <h2 className='detailCard-headline'> Nuevo producto</h2>
                <p className='detailCard-description'>Burro Burro</p>
                <a href='/productos/118' className='detailCard-link'>Ver Producto</a>
            </div>
            <div className='detailCard-image col-sm-12 col-md-4'>
                <img src={product} className="detailCard-image" alt="Producto" />
            </div>
            <div className='detailCard col-sm-12 col-md-4'>
                <p className='detailCard-description'>'Burro Burro' es un juego de cartas, de 2 a 5 jugadores. ¡Haz que tus cartas tengan el valor más bajo de todos y no te conviertas en un burro!</p>
            </div>

        </div>
    )
}


export{DetailCards};