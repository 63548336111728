import "./ProductCardForIndex.css";

function ProductCardForIndex (data){


    return(
        <>
            <div className="ProductCardForIndex col-sm-3 col-xs-1">
                <h4 className="productCardHeadline">{data.productName}</h4>
                <img src={require("../Images/"+ data.productImageCard)} className="productCardImage" alt={data.productName} />
                <a href={"/productos/"+ data.productId} className="productCardButton">Ver Producto</a>
            </div>
        </>
    )
}

export {ProductCardForIndex};