//import BoomLogo from './Images/BoomStoreLogo.webp'
import './App.css';
import React from 'react';
//import { Home } from './Pages/Home';
import { Navigation } from './Navigation/Navigation';
import { Footer } from './Footer/Footer';
import { Router } from './Routes/Routes';
import { BrowserRouter } from 'react-router-dom';





function App() {
  return (
    <BrowserRouter>
      <div className='container'>
        <Navigation/>
        <Router/>
        <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;
