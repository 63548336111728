import ReactPlayer from 'react-player'
import './ProductVideo.css';



export default function ProductVideo({videoURL}){
    return(
        <>
            <div className="video"> 
                <ReactPlayer className='video-player' width='100%' height='100%' controls={true} url={videoURL}/>
            </div>
        </>
    );
}