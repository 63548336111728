import './EmailContactUs.css';
import { useRef} from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';



function EmailContactUs(){

    const ShowAlert = () =>{
        Swal.fire(
            'Mensaje enviado con éxito',
            'Gracias por escribirnos alguien de nuestro equipo lo contactara.',
            'success'
        )
    }

    const form = useRef();

    const SendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_sghs839', 'template_e4yslol', form.current, 'GBav68y44eRQi2ikZ')
        .then((result) => {
            console.log(result.text);
            ShowAlert();
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    };

    return(
        <div className='EmailContactUs row'>
            <div className='col-sm-6 EmailContactUsDesc'>
            <h1 className='EmailContactUsHeadline'>Contactanos</h1>
            <p className='ContactUsDescription'>Si tienes alguna consulta extra no dudes en llenar el siguiente formulario para recibir una respuesta de nuestra parte lo mas pronto posible.</p>
            <p className='ContactUsDescription'>Tambien puedes consultarnos sobre:
                <ul>
                    <li>Juegos que necesitas pero no están en nuestro catálogo</li>
                    <li>Compras al por mayor</li>
                    <li>Recomendaciones de nuevos productos</li>
                    <li>Instrucciones de nuestros juegos</li>
                </ul>
            </p>
            </div>
            <div className='col-sm-6 formContainer'>
                    <div className='formCard form-group'>
                        <h2 className='formHeadline'>Envíanos un mensaje</h2>
                        <form className='formContactUs' ref={form} onSubmit={SendEmail}>
                            <div>
                                <input type='text' className='form-control form-control-lg' placeholder='Nombre completo*' name='user_name' required />
                                <input type='email' className='form-control form-control-lg' placeholder='Correo electrónico*' name='user_email' required />
                                <input type='text' className='form-control form-control-lg' placeholder='Motivo del mensaje*' name='subject' required />
                                <input type='text' className='form-control form-control-lg' placeholder='Numero de teléfono' name='PhoneNumber' />
                                <select class="form-control form-control-lg" name='ContactBy'>
                                <option>Me gustaria ser contactado(a) via email</option>
                                <option>Me gustaria ser contactado(a) via whatsapp </option>
                                </select>
                                <textarea name='message' cols="30" rows="10" className='form-control form-control-lg' />
                                <p className='FormInfo'>* campos requeridos para enviar el mensaje</p>
                                <div class="form-check ">
                                    <input class="form-check-input" type="checkbox" value="Si" id="NewAndInfoCheck" name='user_news'/>
                                    <label class="form-check-label" for="NewAndInfoCheck">Me gustaria recibir correos o mensajes con promociones y noticias de nuevos productos.</label>
                                </div>
                            </div>
                            <button type='submit' className='FormButton'>Enviar</button>
                        </form>
                        
                    </div>
            </div>

        </div>
    )
}


export{EmailContactUs};