import { ProductIndex } from '../ProductsIndex/ProductIndex';
import React from 'react';
import { Helmet } from 'react-helmet';

function Products(){
    return(
        <>
            <Helmet>
                  <title>Catálogo de Juegos de Mesa | Boom Store CR</title>
                  <meta name="description" content="Explora el catálogo completo de Boom Store CR. Encuentra una amplia variedad de juegos de mesa para todas las edades, desde clásicos hasta las últimas novedades. ¡Compra fácil y rápido en línea!" />
            </Helmet>
            <ProductIndex/>
        </>
        
    )
}

export{Products};