import './ShowProduct.css';
import React from 'react';
import banner from '../Images/Spot-it-Classic-banner.webp';
import data from "../DB/Products.json";

function findProduct(id) {
    return data.filter(function (data) {
      return data.productId === id;
    });
}

function generateRandomId(data){
    const ID = (Math.floor(Math.random() * (data.length + 1)).toString());
    return ID;
}



function ShowProduct(){
    const product = findProduct(generateRandomId(data));
    
    return(
        <div className='show-product row' >
            {product.map((info) => {
                return(
                    <React.Fragment key={info.productId}>
                        <h2 className='show-product-headline'>{info.productName}</h2>
                        <div className='show-product-image col-sm-12 col-md-6'>
                            <img src={require("../Images/" + info.productImage)} alt={info.productName} className='show-image' />
                        </div>
                        <div className='show-product-info col-sm-12 col-md-6'>
                            <p className='show-product-description'>{info.productDescription}</p>
                            <a href={'/productos/'+ info.productId} className="show-product-button">
                            Ver producto
                            </a>
                        </div>
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export {ShowProduct};