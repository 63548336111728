import './Navigation.css';
import BoomLogo from '../Images/BoomStoreLogo.webp'
import { Link } from 'react-router-dom';

function Navigation(){
    return(
        <nav className="navbar navbar-expand-lg navigation">
            <div className="container-fluid">
                <a href='/'><img src={BoomLogo} className="navbar-brand nav-logo" alt="Boom Store Logo" /></a>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item"><Link to={'/'} className="nav-link">Principal</Link></li>
                    <li className="nav-item"><Link to={'/productos'} className="nav-link">Productos</Link></li>
                    <li className="nav-item"><Link to={'/contacto'} className="nav-link">Contacto</Link></li>
                </ul>
            </div>
        </nav>
    )
}

export {Navigation};