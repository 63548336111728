import "./SimpleCards.css";
import Image1 from "../Images/Mal_trago_card.webp";
import Image2 from "../Images/Pajarracos_party_card.webp";
import Image4 from "../Images/Cabanga_card.webp";
import Image3 from "../Images/Pelusas_Card.webp";

function SimpleCards() {
  return (
    <div className="Top-Products row">
      <h2 className="top-headline">Lo más nuevo</h2>
      <div className="simple-card col" key={1}>
        <img
          src={Image1}
          className="product-image"
          alt="Spot it Classic game"
        />
        <h4 className="card-headline">Mal Trago</h4>
        <a href="/productos/4" className="card-button">
          Ver producto
        </a>
      </div>
      <div className="simple-card col" key={2}>
        <img
          src={Image2}
          className="product-image"
          alt="La Morada Maldita"
        />
        <h4 className="card-headline">Pajarracos Party</h4>
        <a href="/productos/7" className="card-button">
          Ver producto
        </a>
      </div>
      <div className="simple-card col" key={3}>
        <img src={Image3} className="product-image" alt="Pelusas" />
        <h4 className="card-headline">Pelusas</h4>
        <a href="/productos/32" className="card-button">
          Ver producto
        </a>
      </div>
      <div className="simple-card col" key={4}>
        <img src={Image4} className="product-image" alt="Cabanga" />
        <h4 className="card-headline">¡Cabanga!</h4>
        <a href="/productos/3" className="card-button">
          Ver producto
        </a>
      </div>
    </div>
  );
}

export { SimpleCards };
